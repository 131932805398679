.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:white;
  background-image: url("../asset/web_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 340px;
  /* height: 80%; */
  min-height: 420px;
  max-height: 680PX;
  padding: 20px 40px;
  border-radius: 20px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
  background-color: white;

}

.httxt {
  width: 123px;
  height: 33px;
  margin-top: 12px;
  margin-bottom: 36px;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #2196f3;
}

.logo {
  width:180px;
  margin:auto;
  margin-top:12px;
  margin-bottom: 12px;
}
.logo img {
  width:100%
}

.login__container .ant-divider-inner-text {
  display: inline-block;
  margin-bottom: 0px !important;
  color: #888;
}