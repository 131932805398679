/* .ant-menu {
    margin: 0 !important;
} */

.css-fk96uh
{
    background-color:#8f8f8f69 !important;
}

.css-1rk94hk {
    background-color: #00509d !important;
}


.css-1qck8sa { 
    min-height: 2000px !important;
    overflow-y: hidden !important;
}

.ps-collapsed .css-1qck8sa { 
    min-height: 0 !important;
}